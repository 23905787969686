<template>
  <div>
    <div></div>
    <el-button @click="connectSocket">连接</el-button>
    <el-button @click="sendMsg">发送</el-button>
    <el-button @click="closeSocket">关闭</el-button>
    <el-card>
      <div class="reMsg">
        服务器消息：
        <div class="msg-item" v-for="item, key in msgList" :key="key">
          {{item}}
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
// import { createSocket,sendWSPush,closeWs }  from '@/utils/websocket/websocket'
import { putObject_api } from "@/api/objectStorage/objectStorage.js";
import Md5 from "js-md5";
export default {
  data() {
    return {
      socketUrl: "ws://192.168.10.24:8080/ws/chats/test_demo/",
      msgList: [],
    };
  },
  methods: {
    connectSocket() {
      if (typeof WebSocket === "undefined") {
      } else {
        this.$ws.initWebpack(this.socketUrl);
      }
    },
    closeSocket() {
      this.$ws.onclose();
    },
    // sendMsg() {
    //    const params = {
    //     'message': {data: {name: "test"}, code: 200},
    //     'to_user': 1,
    //     'qid': "test",
    //     'event': "test"
    //   }
    //   this.$ws.send(params)
    // },
    async sendMsg() {
      //文件数据测试
      const data = {
        index: 1,
        name: "add",
        position: [10, 10],
        points: [],
        attrs: {
          rotation: 0.5,
        },
      };
      const count = 380;
      for (let i = 0; i < count; i++) {
        data.points.push([Math.random() * count, Math.random() * count]); //添加顶点数据测试
      }
      const file = new Blob([JSON.stringify(data)], { type: "text/json" });
      const validDataSize = file.size < 1400000; //this.validDataSize(data);

      if (validDataSize) {
        //直接发送消息
        const params = {
          message: { data: data, code: 200 },
          to_user: [1],
          qid: "sdfasd",
          event: "DRAWING",
          user_id: 1,
          gns: "gns",
          type: "321",
        };

        this.$ws.send(params);
      } else {
        //对象存储存json数据

        //下载json测试
        // var a = document.createElement("a");
        // a.href = URL.createObjectURL(file);
        // a.download = "myFile.json";
        // a.click();
        let bucket_name = localStorage.getItem("bucket_name");
        let time = parseInt(new Date().getTime() / 1000);
        let formData = new FormData();
        let gnsurl = "test";
        let folder_name = "user_12/" + gnsurl;
        let apiKey = localStorage.getItem("apiKey");
        formData.append("time", time);
        formData.append("bucket_name", bucket_name);
        formData.append("folder_name", folder_name);
        formData.append("file_type", "OTHER_FILE");
        formData.append("object_name", "");
        formData.append("file", file);

        let res = await putObject_api(formData, {
          sign: Md5(`${apiKey}_${time}`),
          username: "test",
        });
        if (res.code === 200000) {
          // let paramsArr = [{ file_url: res.data.object_path, snow_id }];
          // this.getObjectData(res.data.object_path);
          const params = {
            message: { data: { file_url: res.data.object_path }, code: 200 },
            to_user: 1,
            qid: "qid",
            event: "PERMISSION",
          };
          this.$ws.send(params);
        }
      }
    },
    getSocketData(res) {
      if (res.detail.data) {
        this.msgList.push(res.detail.data);

        const jsonData = res.detail.data.message.data;
        if (jsonData.file_url) {
          this.getObjectData(jsonData.file_url);
        }
      }
      // if (res.detail.data === 'success' || res.detail.data === 'heartBath') return
      // ...业务处理
    },
    validDataSize(data) {
      //判断json文件传输大小小于1.5mb
      return this.memorySizeOf(data) < 1500000;
    },
    memorySizeOf(obj) {
      var bytes = 0;
      function sizeOf(obj) {
        if (obj !== null && obj !== undefined) {
          switch (typeof obj) {
            case "number":
              bytes += 8;
              break;
            case "string":
              bytes += obj.length * 2;
              break;
            case "boolean":
              bytes += 4;
              break;
            case "object":
              var objClass = Object.prototype.toString.call(obj).slice(8, -1);
              if (objClass === "Object" || objClass === "Array") {
                for (var key in obj) {
                  if (!obj.hasOwnProperty(key)) continue;
                  sizeOf(obj[key]);
                }
              } else bytes += obj.toString().length * 2;
              break;
          }
        }
        return bytes;
      }

      function formatByteSize(bytes) {
        if (bytes < 1024) return bytes + " bytes";
        else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KiB";
        else if (bytes < 1073741824)
          return (bytes / 1048576).toFixed(3) + " MiB";
        else return (bytes / 1073741824).toFixed(3) + " GiB";
      }

      // return formatByteSize(sizeOf(obj));
      return sizeOf(obj);
    },
    async getObjectData(fileUrl) {
      let apiKey = localStorage.getItem("apiKey");
      let minio_name = localStorage.getItem("minio_name");
      let bucket_name = localStorage.getItem("bucket_name");
      let time = parseInt(new Date().getTime() / 1000);
      let file_content = await fetch("/file/api/v1/get_object_data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          username: minio_name,
          sign: Md5(`${apiKey}_${time}`),
        },
        body: JSON.stringify({
          time,
          bucket_name,
          object_path: fileUrl,
        }),
      });

      let array_buffer = await file_content.arrayBuffer();
      let u8 = new Uint8Array(array_buffer);

      // let jsonObj = JSON.parse(String.fromCharCode.apply(null, u8));

      let enc = new TextDecoder("utf-8");
      // enc.decode(u8);

      // let data_u8 = new Uint8Array(data_buffer);
    },
  },
  mounted() {
    // 添加socket通知监听
    window.addEventListener("onmessageWS", this.getSocketData);
  },
};
</script>

<style lang="less" scoped>
.reMsg {
  background: #333;
  color: #fff;
  padding: 10px;
  height: 50vh;
  overflow: auto;
  .msg-item {
    padding: 5px;
  }
}
</style>
